import React from "react";
import logo from "../../assets/img/nova-logo-web.png";
import facebook from "../../assets/img/facebook.png";
import linkedin from "../../assets/img/linkedin.png";
import "./style.scss";
import {ParagraphInFooter, ListOurServices} from "../../assets/Variables";
import {PhoneNumber} from "../../assets/PhoneNumber";
import {Address} from "../../assets/Address";
import {Email} from "../../assets/Email";


export default function Footer() {
    return (
        <section className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="footer-wrapper">
                            <div className="row">
                                <div className="col-lg-3 mr-auto footer-content text-center">
                                    <div className="row">
                                        <div className="col-lg-8 footer-content__logo">
                                            <img src={logo} alt="logo"/>
                                        </div>
                                    </div>
                                    <ParagraphInFooter/>
                                </div>
                                <div className="col-lg-4 mr-auto footer-content">
                                    <div className="row">
                                        <div className="col-sm-8 footer-content__cell"></div>
                                    </div>
                                    <h6 className="footer-content__caption">Our services</h6>
                                    <div className="row">
                                        <ul className="col-lg-5 mr-auto">
                                            {ListOurServices.slice(0, 4).map(el => {return (<li key={el}>{el}</li>)})}
                                        </ul>
                                        <ul className="col-lg-5 mr-auto">
                                            {ListOurServices.slice(4).map(el => {return (<li key={el}>{el}</li>)})}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 mr-auto footer-content">
                                    <div className="row">
                                        <div className="col-sm-12 footer-content__cell">
                                            <a className="footer-content__media" href="https://www.facebook.com/Nova-Carriers-Inc-420535948489917/">
                                                <img src={facebook} alt="facebook"/>
                                            </a>
                                            <a href="https://www.linkedin.com">
                                                <img src={linkedin} alt="linkedin"/>
                                            </a>
                                        </div>
                                    </div>
                                    <h6 className="footer-content__caption">Get in Touch</h6>
                                    <address className="footer-content__paragraph">
                                        Nova Carriers, Inc.<br/>
                                        <Address/>
                                        Tel.: <PhoneNumber/> <br/>
                                        Email: <Email/>
                                    </address>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <p className="text-center footer-copyright">Copyright 2019 Nova Carriers, Inc.</p>
                        <p className="text-center">Made by <a href="https://artcode.orks.tk" target="_blank" rel="noopener noreferrer">ArtCode Team</a></p>
                    </div>
                </div>

            </div>

        </section>
    );
}




