import React from "react";
import "./style.scss";
import MapContainer from "./MapContainer";

export function PopupWithMap(props) {
    return (
        <section className="map">
                <MapContainer point={props.point} />
        </section>
    );
}
