import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/Nova-Carriers-logo-web.png";
import ApplyNowButton from "../ApplyNow/ApplyNowButton";
import "./style.scss";
import Menu from "./Menu";
import MenuContacts from "./MenuContacts";
import PodRequestButton from "../PodRequest/PodRequestButton";
import TrackShipmentButton from "../Shipments/TrackShipmentButton";


export default function Header() {
    const [menuActive, setMenuState] = useState(false);

    const resize = () => {
        if (window.innerWidth > 992) {
            setMenuState(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
    });

    return (
        <header>
            <aside className="navbar navbar-expand-xl justify-content-center bg-light fixed-top header">
                <div className="container-xl">
                    <a className="navbar-brand header__logo" href="/">
                        <img className="header__img" src={logo} alt="logo"/>
                    </a>
                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block mr-5">
                        <MenuContacts/>
                    </div>

                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <ApplyNowButton/>
                    </div>

                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <TrackShipmentButton/>
                    </div>

                    <div className="d-none d-sm-none d-md-none d-lg-block d-xl-block">
                        <PodRequestButton/>
                    </div>


                    <div className="d-lg-none d-xl-none text-right navicon"
                         onClick={() => setMenuState(!menuActive)}>
                        <button className={`btn nav-toggle nav-btn ${menuActive && "active"}`}>
                            <span></span></button>
                        <p>{menuActive ? "CLOSE" : "MENU"}</p>
                    </div>
                    <div className={`overlay overlay-boxify ${menuActive && "open"}`}>
                        <a className="navbar-brand header__logo overlay-logo" href="/">
                            <img className="header__img" src={logo} alt="logo"/>
                        </a>
                        <nav className="overlay-menu">
                            <Menu click={() => setMenuState(false)} />
                        </nav>


                        <div className="overlay-wrapper text-center">
                                <div className="overlay-contacts">
                                    <MenuContacts/>
                                </div>
                            <div className="overlay-button">
                                <Link to={`/form`} onClick={() => setMenuState(false)}>
                                    <span className="btn btn-cargo">Apply Now</span>
                                </Link>
                            </div>
                            <div className="overlay-button">
                                <TrackShipmentButton click={() => setMenuState(false)} />
                            </div>
                            <div className="overlay-button">
                                <PodRequestButton click={() => setMenuState(false)}  />
                            </div>
                        </div>

                    </div>
                </div>


                {menuActive && <div className="back-drop" onClick={() => setMenuState(false)}></div>}
            </aside>
            <nav className="navbar navbar-expand justify-content-center fixed-top menu">
                <Menu/>
            </nav>

        </header>
    );
}
