import React, {useRef} from "react";
import SignaturePad from "react-signature-canvas";
import "./style.scss";
import ErrorMessage from "../_helpers/ErrorMessage";

export function Signature({onChange, name, isSubmiting, error, setCanvas}) {
    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();


    const data = () => {
        return sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    };

    setCanvas(sigCanvas);

    return (
        <div>
            <SignaturePad
                ref={sigCanvas}
                canvasProps={{
                    className: `auth-page-form-content__canvas ${!isSubmiting && error && 'canvas__border '}}`
                }}
                clearOnResize={false}
                onEnd={() => onChange(name, data())}
            />
            {!isSubmiting && <ErrorMessage name='signatureURL' value={error}/>}
            <div className="row pb-4 pt-3">
                <div className="col-md-1 pr-0">
                    <button className="btn-clear" onClick={clear}>Clear</button>
                </div>
                <div className="col-md-9 pl-3">
                    <p className="text-clear">By signing here electronically, you agree that your electronic
                        signature is the legal equivalent of your manual
                        signature on this Agreement.</p>
                </div>
            </div>
        </div>
    );
}
