import React, {useState, useEffect} from "react";
import AboutUs from "../AboutUs/AboutUs";
import Services from "../Services/Services";
import JoinUs from "../JoinUs/JoinUs";
import GetQuote from "../GetQuote/GetQuote";
import OurPartners from "../OurPartners/OurPartners";
import ContactUs from "../ContactUs/ContactUs";
import "./style.scss";
import WhatWeDo from "./WhatWeDo";
import {MainHeaderInHomePage, ParagraphInHomePage} from "../../assets/Variables";
import {SocialMedia} from "../SocialMedia/SocialMedia";
import TrackShipmentButton from "../Shipments/TrackShipmentButton";
import PodRequestButton from "../PodRequest/PodRequestButton";


export default function HomePage() {
    const [isSubmiting, setIsSubmiting] = useState(true);
    const [isResize, setIsResize] = useState(true);

    const updateData = (value) => {
        setIsSubmiting(value);
    };

    const resize = () => {
        if (window.innerWidth < 992) {
            setIsResize(false);
        } else {
            setIsResize(true);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
    });

    return (
        <div className="main">
            <section className="home" id="home">
                <div className="container">
                    <div className="row home-content">
                        <div className="col-lg-10 home-caption">
                            <MainHeaderInHomePage/>
                            <ParagraphInHomePage/>
                            {isResize && window.innerWidth > 992 ? <WhatWeDo/> :
                                <div>
                                    <div className="home-button">
                                        <TrackShipmentButton/>
                                    </div>
                                    <div className="home-button">
                                        <PodRequestButton/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <div className="about-background">
                <AboutUs/>
                <Services/>
            </div>

            <GetQuote isSubmiting={isSubmiting}
                      updateData={updateData}/>

            <div className="join-background">
                <JoinUs/>
            </div>
            <div className="contact-background">
                <ContactUs isSubmiting={isSubmiting}
                           updateData={updateData}/>
            </div>
            <OurPartners/>


            <div className="container text-center social">
                <SocialMedia/>
            </div>
        </div>
    );
}

