import React from "react";
import "./style.scss";
import {ListOurServices, ParagraphOurLogisticsServices} from "../../assets/Variables";

export default function Services() {
    return (
            <section className="container-xl text-center services" id="services">
                <div><h1 className="services__title title_black">Our Logistics Services</h1></div>
                <div className="services-caption">
                   <ParagraphOurLogisticsServices/>
                </div>


            <div className="row text-center">
                <div className="col-12 col-sm-6 services-list">
                    <div className="row">
                        <div className="col-6 services-list__item text_bold">{ListOurServices[0]}</div>
                        <div className="col-6 services-list__item text_bold">{ListOurServices[4]}</div>
                    </div>
                    <div className="row">
                        <div className="col-6 services-list__item text_bold">{ListOurServices[1]}</div>
                        <div className="col-6 services-list__item text_bold">{ListOurServices[5]}</div>
                    </div>
                </div>
                <div className="col-12 col-sm-3">
                    <div className="row">
                        <div className="col-6 col-sm-12 services-list__item text_bold">{ListOurServices[2]}</div>
                        <div className="col-6 col-sm-12 services-list__item text_bold">{ListOurServices[6]}</div>
                    </div>
                </div>
                <div className="col-12 col-sm-3">
                    <div className="row">
                        <div className="col-6 col-sm-12 services-list__item text_bold">{ListOurServices[3]}</div>
                        <div className="col-6 col-sm-12 services-list__item text_bold">And many more</div>
                    </div>
                </div>
            </div>
        </section>
    );
}
