import React from "react";
import "./style.scss";
import marker from "../../assets/img/map-marker.png";
import usa from "../../assets/img/map-usa.png";
import ContactForm from "../ContactForm/ContactForm";
import {ParagraphContactUs} from "../../assets/Variables";
import {PhoneNumber} from "../../assets/PhoneNumber";
import {Address} from "../../assets/Address";

const Mark = () => (
    <div className="contact__icon">
        <img src={marker} alt="marker"/>
    </div>
);

export default function ContactUs(props) {
    return (
        <section className="contacts" id="contact-us">
            <div className="container">
                <div className="text-center">
                    <h2 className="contacts__title title_black">Contact Us</h2>
                    <div className="contacts-caption">
                        <ParagraphContactUs/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-4">
                        <div className="contacts-wrapper">
                            <div className="row">
                                <Mark/>
                                <div className="contacts-offices">
                                    <h4 className="contacts-offices__title text_bold">Chicago</h4>
                                    <p className="contacts-offices__text">Headquarters</p>
                                    <p className="text">
                                       <Address/>
                                       </p>
                                    <p className="text contacts-offices__phone">
                                        <PhoneNumber/>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <Mark/>
                                <div className="contacts-offices">
                                    <h4 className="contacts-offices__title text_bold">Phoenix</h4>
                                    <p className="contacts-offices__text">Office</p>
                                </div>
                            </div>

                            <div className="row">
                                <Mark/>
                                <div className="contacts-offices">
                                    <h4 className="contacts-offices__title text_bold">Miami</h4>
                                    <p className="contacts-offices__text">Office</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <ContactForm isSubmiting={props.isSubmiting}
                                     showMessage={props.showMessage}
                                     updateData={props.updateData} />
                    </div>
                </div>

                <div className="row contacts-background">
                    <div className="col-md-12">
                        <img src={usa} alt="usa"/>
                    </div>
                </div>

            </div>

        </section>
    );
}
