import React from "react";
import ellips from "../../assets/img/index24.png";
import "./style.scss";
import {PhoneNumber} from "../../assets/PhoneNumber";


export default function MenuContacts() {
    return (
        <React.Fragment>
            <div className="menu-contacts">
                <div className="menu-contacts__img">
                    <img src={ellips} alt="ellips"/>
                </div>
                <div className="menu-contacts-caption">
                    <span className="menu-contacts-caption__span">CALL US:</span>
                    <p className="menu-contacts-caption__text">
                        <PhoneNumber/>
                    </p>
                </div>

            </div>
        </React.Fragment>
    );

}
