import React from "react";
import aboutus from "../../assets/img/aboutUs.jpg";
import "./style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import {AboutUsText} from "../../assets/Variables";

export default function AboutUs() {
    return (
        <section  name="about" id="about-us" className="container-xl about">
            <div className="row">
                <div className="col-lg-6 col-xl-6 mr-auto about-content">
                <h4 className="about-content__title title_black">About us</h4>
                <AboutUsText/>
            </div>
            <div className="col-xl-1 d-none d-xl-block"></div>
            <div className="col-lg-5 col-xl-5  mr-auto about-image">
                <img src={aboutus} alt="aboutus"/>
            </div>
            </div>
        </section>
    );
}
