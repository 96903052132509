import React, {useState} from "react";
import {Link} from "react-router-dom";
import "../GetQuote/style.scss";
import {Modal} from "react-bootstrap";
import ModalForm from "./PodRequestForm";


export default function PodRequestButton(props) {
    const [modalShow, setModalShow] = useState(false);

    const backToMenu = event => {
         try {
             if (window.innerWidth < 767) {
                 props.click();
             }
         } catch (error) {
             console.log(error);
         }
    };

    const MyModal = (props) => {


        return (
            <Modal
                {...props}
                centered>

                <div className="requests-modal">
                    <div className="text-right navicon-new" onClick={() => setModalShow(false)}>
                        <button className='nav-toggle-new active'><span></span></button>
                        <p className="pointer">CLOSE</p>
                    </div>
                    <ModalForm click={() => {
                        setModalShow(false);
                    }}/>
                </div>
            </Modal>
        );
    };

    return (
        <React.Fragment>
            <Link to={`/`} onClick={() => {
                setModalShow(true);
                backToMenu()}}
            >
                <span className="btn btn-cargo">POD REQUEST</span>
            </Link>

            <MyModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>


    );
}
