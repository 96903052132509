import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./Components/HomePage/HomePage";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import AuthPage from "./Components/AuthPage/AuthPage";
import {siteKey} from "./Components/_helpers/reCaptchaKeys";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

class App extends React.Component {
    render() {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                <Router>
                    <Header/>
                    <div className="app">
                        <Switch>
                            <Route exact path="/" component={HomePage}/>

                            <Route exact path="/form" component={AuthPage}/>
                            <Route path='*' component={HomePage} />
                        </Switch>
                    </div>
                    <Footer/>
                </Router>
            </GoogleReCaptchaProvider>
        );
    }
}

export default App;
