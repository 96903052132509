import React from "react";
import { Link }from "react-router-dom";
import "./style.scss";


export default function ApplyNowButton() {
  return (
    <div>
      <Link to={`/form`}  onClick={() => window.scrollTo(0, 0)}>
        <span className="btn btn-cargo text-uppercase">APPLY NOW</span>
      </Link>
    </div>
  );
}
