import React from "react";
import "../../assets/variables.scss";

const ErrorMessage = ({ name, value }) => {
  return (
      <div>
          <span className='errors' name={name}>{value}</span>
      </div>
  );
};

export default ErrorMessage;