import React, {useState} from "react";
import Field from "../_helpers/Fields";
import "./style.scss";
import {client} from "../../api/client";
import ErrorMessage from "../_helpers/ErrorMessage";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

export default function GetQuoteForm(props): React.FunctionComponent {

    const initialState = {
        companyName: '',
        originZip: '',
        email: '',
        destinationZip: '',
        mcNumber: '',
        weight: '',
        pallets: '',
    };

    const [requestForm, setRequestForm] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const [hideForm, setHideForm] = useState(false);

    const showForm = () => {
        setHideForm(false);
    };


    const onChange = (name, value) => {
        setRequestForm({...requestForm, [name]: value});
    };

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleSubmit = event => {
        document.getElementById('quote').disabled = true;
        event.preventDefault();
        executeRecaptcha('request_quote').then((token) => {
            requestForm.token = token;
            requestForm.form = 'request-quote';
            client.post('/react.php', requestForm)
                .then(response => {
                    const result = response.data.result;
                    const resErrors = response.data.errors;
                    if (result === 1) {
                        document.getElementById('quote').disabled = false;
                        if (resErrors.hasOwnProperty('captcha')) {
                            alert(resErrors.captcha);
                        }
                        setErrors(resErrors);
                        props.updateData(false);
                    } else if (result === 0) {
                        document.getElementById('quote').disabled = false;
                        setHideForm(true);
                        setRequestForm(initialState);
                        props.updateData(true);
                        setErrors(initialState);
                    }
                })
                .catch(error => {
                    console.log("An error occurred:", error);
                });
        });
    };


    return (
        <React.Fragment>
            <div className="container requests-form">
                {!hideForm ?
                <form onSubmit={handleSubmit}>
                    <div className='container requests-form-content'>
                        <div className="row">
                            <div className="col-md-6 mr-auto">
                                <Field
                                    className={`input ${!props.isSubmiting && errors.companyName && "error-input"}`}
                                    label="Company Name *"
                                    name="companyName"
                                    value={requestForm.companyName}
                                    placeholder="Company Name *"
                                    type="text"
                                    onChange={onChange}
                                />

                                {!props.isSubmiting && <ErrorMessage name='companyName' value={errors.companyName}/>}

                            </div>
                            <div className="col-md-6 mr-auto">
                                <Field
                                    className={`input ${!props.isSubmiting && errors.email && "error-input"}`}
                                    label="Email *"
                                    name="email"
                                    value={requestForm.email}
                                    placeholder="Email *"
                                    type="text"
                                    onChange={onChange}
                                />

                                {!props.isSubmiting && <ErrorMessage name='email' value={errors.email}/>}

                            </div>
                            <div className="col-6 mr-auto">
                                <Field
                                    className={`input ${!props.isSubmiting && errors.originZip && "error-input"}`}
                                    label="Origin ZIP *"
                                    name="originZip"
                                    value={requestForm.originZip}
                                    placeholder="Origin ZIP *"
                                    type="text"
                                    onChange={onChange}
                                />

                                {!props.isSubmiting && <ErrorMessage name='originZip' value={errors.originZip}/>}

                            </div>
                            <div className="col-6 mr-auto">
                                <Field
                                    className={`input ${!props.isSubmiting && errors.destinationZip && "error-input"}`}
                                    label="Destination ZIP *"
                                    name="destinationZip"
                                    value={requestForm.destinationZip}
                                    placeholder="Destination ZIP *"
                                    type="text"
                                    onChange={onChange}
                                />
                                {!props.isSubmiting &&
                                <ErrorMessage name='destinationZip' value={errors.destinationZip}/>}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mr-auto">
                                <Field
                                    className='input'
                                    label="MC Number"
                                    name="mcNumber"
                                    value={requestForm.mcNumber}
                                    placeholder="MC Number"
                                    type="text"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6 col-md-4 mr-auto">
                                <Field
                                    className='input'
                                    label="Weight"
                                    name="weight"
                                    value={requestForm.weight}
                                    placeholder="Weight"
                                    type="text"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6 col-md-4 mr-auto">
                                <Field
                                    className='input'
                                    label="No. of Pallets"
                                    name="pallets"
                                    value={requestForm.pallets}
                                    placeholder="No. of Pallets"
                                    type="text"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="text-center requests-form-content__button">
                            <button id="quote" type="submit" className="btn btn-cargo">GET A QUOTE</button>
                        </div>
                    </div>
                </form>
                :
                <div className="check-content text-center">
                    <div className="check-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" height="68" viewBox="0 0 24 24" width="68">
                            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                        </svg>
                    </div>
                    <h3 className="font-weight-bold">Success</h3>
                    <p style={{fontSize: "16px"}}>Your message has been sent successfully!</p>
                    <div className="text-center form-content__button">
                        <button className="btn-sendAgain" onClick={showForm}>Send another message</button>
                    </div>
                </div>
                }
            </div>
        </React.Fragment>
    );
}
