import React, {useState} from "react";
import "./style.scss";
import {client} from "../../api/client";
import Field from "../_helpers/Fields";
import ErrorMessage from "../_helpers/ErrorMessage";


export default function PopupShipments(props) {
    const onGetResult = props.onGetResult;

    const initialState = {
        truckNo: '', //1003
        trailerNo: '' //5007
    };

    const textError = {
        shipment: "Invalid truck or trailer number",
        truckNo: "Invalid truck number",
        trailerNo: "Invalid trailer number",
        notFound: "Can't find your shipment!"
    };


    const [modalShipments, setModalShipments] = useState(initialState);
    const [error, setError] = useState(true);
    const [resError, setResError] = useState(true);

    let valid = true;

    const validate = () => {
        if (!modalShipments.truckNo && !modalShipments.trailerNo) {
            valid = false;
            setError(false);
        }
        return valid;
    };

    const onChange = (name, value) => {
        setModalShipments({...modalShipments, [name]: value});
    };


    const handleSubmit = event => {
        event.preventDefault();
        if (validate()) {
            setError(true);
            setResError(true);
            console.log(modalShipments);
            client.get(`/tracking/track.php?TruckNo=${modalShipments.truckNo}&TrailerNo=${modalShipments.trailerNo}`)
                .then(response => {
                    console.log(response);
                    onGetResult(response.data);
                })
                .catch(error => {
                    setResError(false);
                    console.log("An error occurred:", error);
                });
        } else {
            console.error("FORM INVALID");
        }

    };

    return (
        <React.Fragment>
            <div>
                <h2 className="modal-form__title title_black">Track your shipment</h2>
                <form className="ui form error" onSubmit={handleSubmit}>
                    <div className="container modal-form-content">
                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    className="input"
                                    label="TruckNo *"
                                    name="truckNo"
                                    value={modalShipments.truckNo}
                                    placeholder="TruckNo *"
                                    type="number"
                                    onChange={onChange}
                                />
                                {!error &&
                                <ErrorMessage className='errors' name='truckNo' value="Invalid truck or trailer number"/>}
                            </div>
                            <div className="col-md-6">
                                <Field
                                    className="input"
                                    label="TrailerNo *"
                                    name="trailerNo"
                                    value={modalShipments.trailerNo}
                                    placeholder="TrailerNo *"
                                    type="number"
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="text-center modal-form__button">
                            {!resError &&
                            <ErrorMessage className='errors' name='trailerNo' value={textError.notFound}/>}
                            <button type="submit" className="btn btn-cargo mt-3">
                                TRACK
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </React.Fragment>
    );
}



