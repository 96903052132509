import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function DeliveryDate({onChange, deliveryDate, className}) {
    return (
        <DatePicker
            className={className}
            selected={deliveryDate}
            placeholderText='Delivery Date *'
            onChange={date => onChange("deliveryDate", date)}/>
    );
};