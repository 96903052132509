import React from "react";
import "./style.scss";
import {PhoneNumber} from "../../assets/PhoneNumber";


export function CallUs() {
    return (
        <React.Fragment>
            <div className="form-call-us">
                <div className="container-fluid text-center">
                    <h1 className="form-call-us__title">Feel free to call if you have any questions</h1>
                    <p className="form-call-us__phone"><PhoneNumber/></p>
                    <p className="form-call-us__span text-uppercase">CALL US FREE</p>
                </div>
            </div>
        </React.Fragment>
    );
}
