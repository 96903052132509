import React from "react";
import Dropzone from 'react-dropzone'
import pdficon from "../../assets/img/pdf-icon.png";

class DragAndDrop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            thumbs: '',
            files: []
        }
    };

    onDrop = (acceptedFiles) => {
        if (acceptedFiles.length < 2) {
            const droppedFiles = acceptedFiles.map(file => {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.props.onChange(this.props.name, event.target.result);
                    };
                    reader.readAsDataURL(file);
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    });
                    return file;
                }
            );

            this.setState(prevState => {
                return {
                    thumbs: this.getThumbs(droppedFiles)
                }
            });
        }
    };

    clearThumbs = () => {
        this.setState(prevState => {
            return {
                thumbs: ''
            }
        });
    };

    getThumbs = (files) => {
        return files.map(file => (
            <div className="drop-preview" key={file.name}>
                {
                    <div className="drop-preview-inner">
                        <img src={file.type.search('image') !== -1 ? file.preview : pdficon} alt='img'
                             className="drop-preview-inner__img"/>
                    </div>
                }
                <p className="drop-preview-inner__info">
                    {file.path} <br/>
                    <span>{Math.floor(file.size * 0.001)} kB</span>
                </p>
                <br/>
            </div>

        ))
    };


    render() {
        return (
            <div className="row drop-zone">
                <div className="col-md-12">
                    <Dropzone onDrop={this.onDrop}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({})} className={`drop-box ${this.props.className}`}>
                                <input {...getInputProps()} />
                                {window.innerWidth >= 1024 ?
                                    <p>Drag & drop (or) <span className="btn-clear">Choose File</span></p> :
                                    <p><span className="btn-clear">Choose File</span></p>
                                }
                            </div>
                        )}
                    </Dropzone>

                </div>
                <div className="col-md-12">
                    <div className="drop-result">{this.state.thumbs}</div>
                </div>
            </div>
        );
    }

}

export default DragAndDrop;
