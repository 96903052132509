import React, {Component} from "react";
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {mapKey} from "../../_helpers/googleMapsKeys";

const mapStyles = {
    width: '95%',
    height: '95%',

};

export class MapContainer extends Component {
    render() {
        return (
            <div className="mapStyle">
                <Map
                    style={mapStyles}
                    google={this.props.google}
                    zoom={4}
                    initialCenter={this.props.point}
                >
                    <Marker position={this.props.point}/>
                </Map>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: mapKey
})(MapContainer)
