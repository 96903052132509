import React from "react";
import banner from "../../assets/img/Nova-Carriers-banner.jpg";
import ApplyNowButton from "../ApplyNow/ApplyNowButton";
import "./style.scss";
import MoreInfo from "../MoreInfo/MoreInfo";

export default function JoinUs() {
  return (
    <section className="join text-center" id="join-us">
      <div className="container join-content">
          {window.innerWidth <= 992 && <div className="join-content__title">Join Us</div>}
        <img src={banner} alt="banner" />
      </div>
        <ApplyNowButton />
        <MoreInfo/>
    </section>
  );
}
