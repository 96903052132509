import React from "react";
import "./style.scss";


export default function WhatWeDo() {
    const goto = e => {
        e.preventDefault();
        const selector = document.getElementById(e.target.getAttribute('target-id'));
        console.log(selector);
        if (selector) {
            const element = selector.firstElementChild;
            const position = element.getBoundingClientRect();
            if (position.top >= 0 && position.bottom <= window.innerHeight) return;
            element.scrollIntoView({block: "center", behavior: "smooth"});
        }
    };
    return (
        <React.Fragment>
            <button target-id="about-us" className="btn btn-cargo" onClick={e => goto(e)}>What We Do</button>
        </React.Fragment>
    );
}

