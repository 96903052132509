import React from "react";
import "./style.scss";
import {
    Authorization,
    AuthorizationSecondParagraph,
    Disclosure,
    RegardingBackgroundReports, StateLawNotices
} from "../../assets/Variables";

export default function FormText() {
    return (
        <div className="container">
            <div className="form-text">PSP / MVR</div>
            <h1 className="form-text__title text-center text-uppercase">THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS
                FOR <br/>MANDATORY USE BY ALL ACCOUNT HOLDERS</h1>
            <h2 className="form-text__title text-center text-uppercase">IMPORTANT DISCLOSURE</h2>
            <h2 className="form-text__title text-center text-uppercase">REGARDING BACKGROUND REPORTS FROM THE PSP Online
                Service</h2>

            <RegardingBackgroundReports/>

            <h3 className="form-text__title secondary text-center text-uppercase">AUTHORIZATION</h3>
            <Authorization/>

            <h3 className="form-text__title secondary text-center text-uppercase">FAIR CREDIT REPORTING ACT DISCLOSURE AND
                AUTHORIZATION TO RELEASE INFORMATION </h3>
            <h3 className="form-text__title text-center text-uppercase"> DISCLOSURE</h3>

            <Disclosure/>

            <h3 className="form-text__title text-center text-uppercase">AUTHORIZATION</h3>
            <AuthorizationSecondParagraph/>

        </div>

    );
}

export function FormLawNotice() {
    return (
        <React.Fragment>
            <h3 className="form-text__title secondary text-center text-uppercase">ADDITIONAL STATE LAW NOTICES</h3>
            <StateLawNotices/>
        </React.Fragment>
    );
}

