import React from "react";

export const MainHeaderInHomePage = () => {
    return (
        <React.Fragment>
            <h1 className="home-caption__title title_white">Excellence in motion</h1>
        </React.Fragment>
    );
};

export const ParagraphInHomePage = () => {
    return (
        <React.Fragment>
            <p className="home-caption__subtitle subtitle_white">Nova Carriers, Inc. providing <strong>excellent
                transportation</strong> <br/>
                and <strong>logistic services</strong> throughout all lower 48 states.</p>
        </React.Fragment>
    );
};

export const AboutUsText = () => {
    return (
        <React.Fragment>
            <p className="text"> Nova Carriers, Inc. was established in 2012 by two motivated and self-driven guys.
                The reason why we grow slower is because we take pride in our outstanding customer service.
                We have always been believers in quality over quantity!</p>
            <p className="about-content__text text_bold">
                If you want to experience our outstanding customer service - give us
                a call today.
            </p>
            <p className="text"> Well that’s a very good question. With over 40 years of experience in ground logistics,
                we have you covered from our integrated TMS system, as well as our in-house tracking.
                Just think about it!
                You are about to go to sleep, but you want to see where your truck is.
                With one simple click, you can track your shipment in-progress instantly! No calls required.
            </p>
        </React.Fragment>
    );
};


export const ParagraphOurLogisticsServices = () => {
    return (
        <React.Fragment>
            <h4 className="services-caption__title subtitle_black">Ground transportation</h4>
            <p className="text">
                With over 40 years of experience, we are confident that we will the best <br/>
                solution for your needs:
            </p>
        </React.Fragment>
    );
};

export const ListOurServices = [
    'Expedited ground', 'Dedicated', 'Contract', 'Storage', 'Cross-docking', 'Final mile delivery', 'Consulting'
];


export const ParagraphGetQuickQuote = () => {
    return (
        <React.Fragment>
            <p className="requests-caption__subtitle subtitle_white">Just fill-in the online form and we will contact
                you.
                <br/>Or feel free to call us.</p>
        </React.Fragment>
    );
};

export const ParagraphContactUs = () => {
    return (
        <React.Fragment>
            <p className="text">Whether you have a question about our services, pricing,<br/>
                tracking, job or otherwise, we are ready to answer all your<br/>
                questions.</p>
        </React.Fragment>
    );
};


export const RegardingBackgroundReports = () => {
    return (
        <React.Fragment>
            <p className="form-text__paragraph">In connection with your application for employment with <strong>Nova
                Carriers, Inc.</strong>
                (“Prospective Employer”),
                Prospective Employer, its employees, agents or contractors may obtain one or more reports regarding
                your driving, and safety inspection history from the Federal Motor Carrier Safety Administration
                (FMCSA).</p>
            <p className="form-text__paragraph">
                When the application for employment is submitted in person, if the Prospective Employer uses any
                information it obtains from FMCSA in a decision to not hire you or to make any other adverse
                employment decision regarding you, the Prospective Employer will provide you with a copy of the
                report upon which its decision was based and a written summary of your rights under the Fair Credit
                Reporting Act before taking any final adverse action. If any final adverse action is taken against
                you based upon your driving history or safety report, the Prospective Employer will notify you that
                the action has been taken and that the action was based in part or in whole on this report.</p>
            <p className="form-text__paragraph">
                When the application for employment is submitted by mail, telephone, computer, or other similar
                means, if the Prospective Employer uses any information it obtains from FMCSA in a decision to not
                hire you or to make any other adverse employment decision regarding you, the Prospective Employer
                must provide you within three business days of taking adverse action oral, written or electronic
                notification: that adverse action has been taken based in whole or in part on information obtained
                from FMCSA; the name, address, and the toll free telephone number of FMCSA; that the FMCSA did not
                make the decision to take the adverse action and is unable to provide you the specific reasons why
                the adverse action was taken; and that you may, upon providing proper identification, request a free
                copy of the report and may dispute with the FMCSA the accuracy or completeness of any information or
                report. If you request a copy of a driver record from the Prospective Employer who procured the
                report, then, within 3 business days of receiving your request, together with proper identification,
                the Prospective Employer must send or provide to you a copy of your report and a summary of your
                rights under the Fair Credit Reporting Act.
            </p>
            <p className="form-text__paragraph">
                Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety information
                has the capability to correct any safety data that appears to be incorrect. You may challenge the
                accuracy of the data by submitting a request to https://dataqs.fmcsa.dot.gov. If you challenge crash
                or inspection information reported by a State, FMCSA cannot change or correct this data. Your
                request will be forwarded by the DataQs system to the appropriate State for adjudication.
            </p>
            <p className="form-text__paragraph">
                Any crash or inspection in which you were involved will display on your PSP report. Since the PSP
                report does not report, or assign, or imply fault, it will include all Commercial Motor Vehicle
                (CMV) crashes where you were a driver or co-driver and where those crashes were reported to FMCSA,
                regardless of fault. Similarly, all inspections, with or without violations, appear on the PSP
                report. State citations associated with Federal Motor Carrier Safety Regulations (FMCSR) violations
                that have been adjudicated by a court of law will also appear, and remain, on a PSP report.
            </p>
            <p className="form-text__paragraph">
                The Prospective Employer cannot obtain background reports from FMCSA without your authorization
            </p>
        </React.Fragment>
    );
};


export const Authorization = () => {
    return (
        <React.Fragment>
            <p className="form-text__paragraph">If you agree that the Prospective Employer may obtain such background
                reports, please read the
                following and sign below:
            </p>
            <p className="form-text__paragraph">
                I authorize <strong>Nova Carriers, Inc.</strong> (“Prospective Employer”) to access the FMCSA Pre-Employment
                Screening
                Program (PSP) system to seek information regarding my commercial driving safety record and
                information regarding my safety inspection history. I understand that I am authorizing the release
                of safety performance information including crash data from the previous five (5) years and
                inspection history from the previous three (3) years. I understand and acknowledge that this release
                of information may assist the Prospective Employer to make a determination regarding my suitability
                as an employee.
            </p>
            <p className="form-text__paragraph">
                I further understand that neither the Prospective Employer nor the FMCSA contractor supplying the
                crash and safety information has the capability to correct any safety data that appears to be
                incorrect. I understand I may challenge the accuracy of the data by submitting a request to
                https://dataqs.fmcsa.dot.gov. If I challenge crash or inspection information reported by a State,
                FMCSA cannot change or correct this data. I understand my request will be forwarded by the DataQs
                system to the appropriate State for adjudication.
            </p>
            <p className="form-text__paragraph">
                I understand that any crash or inspection in which I was involved will display on my PSP report.
                Since the PSP report does not report, or assign, or imply fault, I acknowledge it will include all
                CMV crashes where I was a driver or co-driver and where those crashes were reported to FMCSA,
                regardless of fault. Similarly, I understand all inspections, with or without violations, will
                appear on my PSP report, and State citations associated with FMCSR violations that have been
                adjudicated by a court of law will also appear, and remain, on my PSP report.
                I have read the above Disclosure Regarding Background Reports provided to me by Prospective Employer
                and I understand that if I sign this Disclosure and Authorization, Prospective Employer may obtain a
                report of my crash and inspection history. I hereby authorize Prospective Employer and its
                employees, authorized agents, and/or affiliates to obtain the information authorized above.
            </p>

        </React.Fragment>

    );
};

export const Disclosure = () => {
    return (
        <React.Fragment>
            <p className="form-text__paragraph">In connection with your application for employment at the <b>Nova
                Carriers, Inc.</b> ,
                the <b>Nova Carriers, Inc.</b> may
                verify information within the application or other materials relating to your application for
                employment. As part of that verification process, the <b>Nova Carriers, Inc.</b> will request, from a
                background
                check vendor, an investigative consumer report (“Background Check Report”) on you as defined in the
                Fair Credit Reporting Act.
            </p>
            <p className="form-text__paragraph">
                For Nova Carriers, Inc. purposes, a Background Check Report will consist of a criminal background check,
                employment verification, education verification, reference check, public records check, driving
                records check, and professional license check. It will not include a credit check, although
                information that pertains to your credit may be contained among public records (i.e., bankruptcy
                filings). The information obtained in the Background Check Report will be utilized only during the
                initial employment application process. In the event that information from the report is utilized in
                whole or in part in making an adverse decision, before making the adverse decision, we will provide
                to you a copy of the Background Check Report and a description in writing of your rights under the
                Fair Credit Reporting Act, 15 U.S.C. § 1681 et seq.</p>

        </React.Fragment>

    );
};

export const AuthorizationSecondParagraph = () => {
    return (
        <React.Fragment>
            <p className="form-text__paragraph">By my signature below, I expressly authorize and instruct the background
                check vendor to perform and release to the Nova Carriers, Inc. a Background Check Report on me
                at the request of the Nova Carriers, Inc. in conjunction with my job application. I understand
                that, to the extent allowed by law, information contained in my job application or otherwise
                disclosed by me, if any, may be used for the purpose of conducting a background check.
            </p>
            <p className="form-text__paragraph">
                By my signature below, I also authorize the disclosure to the Nova Carriers, Inc. and/or to the
                background check vendor of information concerning my employment history, earning history, education,
                motor vehicle history and standing, criminal history, and all other publicly available information
                the Nova Carriers, Inc. deems pertinent by any individual, corporation or other private or
                public entity, including without limitation the following: employers; learning institutions,
                including colleges and universities; law enforcement agencies; federal, state and local courts; the
                military; motor vehicle records agencies; and other public sources. I hereby release and hold the
                background check vendor and the Nova Carriers, Inc., its officers, directors, employees, and
                trustees harmless from any and all liability with respect to the Background Check Report,
                investigations, verifications, and/or the use of any information relevant to my employment. By my
                signature below, I acknowledge that this Authorization Form, in original, faxed, photocopied or
                electronic form, will be valid for any reports that may be requested by the Nova Carriers, Inc. </p>

        </React.Fragment>

    );
};


export const StateLawNotices = () => {
    return (
        <React.Fragment>
            <p className="form-text__paragraph"><b>For Maine Applicants Only</b><br/>
                Upon request, you will be informed whether or not an investigative consumer report was requested,
                and if such a report was requested, the name and address of the consumer reporting agency furnishing
                the report. You may request and receive from us, within 5 business days of our receipt of your
                request, the name, address and telephone number of the nearest unit designated to handle inquiries
                for the consumer reporting agency issuing an investigative consumer report concerning you. You also
                have the right, under Maine law, to request and promptly receive from all such agencies copies of
                any reports.
            </p>
            <p className="form-text__paragraph">
                <b>For New York Applicants Only</b><br/>
                You have the right, upon written request, to be informed of whether or not a consumer report was
                requested. If a consumer report is requested, you will be provided with the name and address of the
                consumer reporting agency furnishing the report.
            </p>
            <p className="form-text__paragraph">
            <b>For California Applicants Only</b><br/>
            You may view the file maintained on you by TrueScreen during normal business hours. You may also obtain a
            copy of this file upon providing proper identification and paying the costs of duplication services, by
            appearing at TrueScreen’s offices in-person during normal business hours and with reasonable notice. You may
            also receive by mail, or receive a summary of the file by telephone. TrueScreen has trained personnel
            available to explain your file to you, including any coded information. If you appear in-person, you must be
            accompanied by one other person, provided that person provides proper identification.
        </p>
            <p className="form-text__paragraph">
                <b>For Minnesota, Oklahoma and California Applicants Only:</b><br/>
                In connection with your application for employment, your investigative consumer report may be
                obtained and reviewed. Under California, Minnesota and Oklahoma law, you have a right to a free copy
                of your investigative consumer report by checking the appropriate box below.</p>

        </React.Fragment>

    );
};


export const ParagraphInFooter = () => {
    return (
        <React.Fragment>
            <p className="footer-content__text mr-auto">
                Nova Carriers, Inc. is dedicated to providing
                the highest quality transportation and
                logistics services across the country. We
                specialize in Dry Freight, Reefer Freight, Solo
                and Team Service, Dry-Age, Storage and more.
            </p>
        </React.Fragment>
    );
};
