import React, {useState} from "react";
import Field from "../_helpers/Fields";
import {client} from "../../api/client";
import "./style.scss";
import ErrorMessage from "../_helpers/ErrorMessage";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


export default function ContactForm(props): React.FunctionComponent {
    const initialState = {
        firstName: '',
        email: '',
        lastName: '',
        phone: '',
        subject: 'General question',
        message: ''
    };

    const [contactForm, setContactForm] = useState(initialState);
    const [errors, setErrors] = useState(initialState);
    const [hideForm, setHideForm] = useState(false);

    const showForm = () => {
        setHideForm(false);
    };

    const onChange = (name, value) => {
        setContactForm({...contactForm, [name]: value});
    };

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleSubmit = event => {
        document.getElementById('contact').disabled = true;
        event.preventDefault();
        executeRecaptcha('contact_us').then((token) => {
            contactForm.token = token;
            contactForm.form = 'contact-us';
            client.post('/react.php', contactForm)
                .then(response => {
                    const result = response.data.result;
                    const resErrors = response.data.errors;
                    if (result === 1) {
                        document.getElementById('contact').disabled = false;
                        if (resErrors.hasOwnProperty('captcha')) {
                            alert(resErrors.captcha);
                        }
                        setErrors(resErrors);
                        props.updateData(false);
                    } else if (result === 0) {
                        document.getElementById('contact').disabled = false;
                        setHideForm(true);
                        setContactForm(initialState);
                        props.updateData(true);
                        setErrors(initialState);
                    }
                })
                .catch(error => {
                    console.log("An error occurred:", error);
                });
        });
    };

    return (
        <React.Fragment>
            <div className="container contacts-form">
                {!hideForm ? <form className="ui form error" onSubmit={handleSubmit}>
                        {/*<Captcha counter={captchaCounter} />*/}
                        <div className="container contacts-form-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        className={`input ${!props.isSubmiting && errors.firstName && "error-input"}`}
                                        label="First Name *"
                                        name="firstName"
                                        value={contactForm.firstName}
                                        placeholder="First Name *"
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!props.isSubmiting && <ErrorMessage name='firstName' value={errors.firstName}/>}
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        className='input'
                                        label="Last Name"
                                        name="lastName"
                                        value={contactForm.lastName}
                                        placeholder="Last Name"
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        className={`input ${!props.isSubmiting && errors.email && "error-input"}`}
                                        label="Email *"
                                        name="email"
                                        value={contactForm.email}
                                        placeholder="Email *"
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!props.isSubmiting && <ErrorMessage name='email' value={errors.email}/>}
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        className='input'
                                        label="Phone"
                                        name="phone"
                                        value={contactForm.phone}
                                        placeholder="Phone"
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="subject">Subject</label> <br/>
                                    <select className='input'
                                            id="subject" name="subject"
                                            value={contactForm.subject}
                                            onChange={e => onChange(e.target.name, e.target.value)}>
                                        <option value="General question">General question</option>
                                        <option value="Services">Services</option>
                                        <option value="Job">Job</option>
                                        <option value="Partnership">Partnership</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label htmlFor="message">Message *</label>
                                    <textarea
                                        className={`input textarea ${!props.isSubmiting && errors.message && "error-input"}`}
                                        id="message"
                                        name="message"
                                        placeholder="Message *"
                                        value={contactForm.message}
                                        onChange={e => onChange(e.target.name, e.target.value)}/>
                                </div>
                                {!props.isSubmiting && <ErrorMessage name='message' value={errors.message}/>}
                            </div>


                            <div className="text-center contacts-form-content__button">
                                <button id="contact" type="submit" className="btn btn-cargo">
                                    SEND MESSAGE
                                </button>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="check-content text-center">
                        <div className="check-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" height="68" viewBox="0 0 24 24" width="68">
                                <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
                                <path
                                    d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                            </svg>
                        </div>
                        <h3 className="font-weight-bold">Success</h3>
                        <p style={{fontSize: "16px"}}>Your message has been sent successfully!</p>
                        <div className="text-center form-content__button">
                            <button className="btn-sendAgain" onClick={showForm}>Send another message</button>
                        </div>
                    </div>
                    }
            </div>
        </React.Fragment>
    );
}
