import React from "react";
import { useLocation } from "react-router-dom";
import {HashLink} from 'react-router-hash-link';
import {Link} from "react-scroll";
import "./style.scss";



const Items = {
    home: 'home',
    aboutUs: 'about-us',
    services: 'services',
    requests: 'requests',
    joinUs: 'join-us',
    contactUs: 'contact-us'
};


export default function Menu(props) {
    let location = useLocation();

    const backToMenu = () => {
        const width = window.innerWidth;
        if (width < 767) {
            props.click();
        }

    };

    return (
        <React.Fragment>
            <ul className="navbar-nav header-list text-right">
                <li className="nav-item header-list__item">
                    {location.pathname === `/form` ?
                        <HashLink to={`/#${Items.home}`}
                                  onClick={() => {
                                      window.scrollTo(0, 0);
                                      backToMenu()}}
                                  className="nav-link header-list__link">
                            Home
                        </HashLink> :
                        <Link activeClass="active"
                              to={Items.home}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className="nav-link header-list__link"
                              onClick={() => backToMenu()}>
                            Home
                        </Link>
                    }
                </li>
                <li className="nav-item header-list__item">
                    {location.pathname === `/form` ?
                        <HashLink to={`/#${Items.aboutUs}`}
                                  onClick={() => backToMenu()}
                                  className="nav-link header-list__link">
                            About Us
                        </HashLink> :
                        <Link activeClass="active"
                              to={Items.aboutUs}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className="nav-link header-list__link"
                              onClick={() => backToMenu()}>
                            About Us
                        </Link>
                    }
                </li>
                <li className="nav-item header-list__item">
                    {location.pathname === `/form` ?
                        <HashLink to={`/#${Items.services}`}
                                  onClick={() => backToMenu()}
                                  className="nav-link header-list__link">
                            Services
                        </HashLink> :
                        <Link activeClass="active"
                              to={Items.services}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className="nav-link header-list__link"
                              onClick={() => backToMenu()}>
                            Services
                        </Link>
                    }
                </li>


                <li className="nav-item header-list__item">
                    {location.pathname === `/form` ?
                        <HashLink to={`/#${Items.joinUs}`}
                                  onClick={() => backToMenu()}
                                  className="nav-link header-list__link">
                            Join Us
                        </HashLink> :
                        <Link activeClass="active"
                              to={Items.joinUs}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className="nav-link header-list__link"
                              onClick={() => backToMenu()}>
                            Join Us
                        </Link>
                    }
                </li>
                <li className="nav-item header-list__item">
                    {location.pathname === `/form` ?
                        <HashLink to={`/#${Items.contactUs}`}
                                  onClick={() => backToMenu()}
                                  className="nav-link header-list__link">
                            Contact Us
                        </HashLink> :
                        <Link activeClass="active"
                              to={Items.contactUs}
                              spy={true}
                              smooth={true}
                              offset={-70}
                              duration={500}
                              className="nav-link header-list__link"
                              onClick={() => backToMenu()}>
                            Contact Us
                        </Link>
                    }
                </li>
            </ul>
        </React.Fragment>
    );
}
