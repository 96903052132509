import React from "react";

const FieldsCheckbox = ({label, name, type, placeholder, value, checked, onChange}) => {
    return (
        <label className="checkbox-container">
            <input
                type={type}
                name={name}
                value={value}
                checked={checked}
                placeholder={placeholder}
                onChange={e => onChange(e.target.name, e.target.checked)}
            />
            <span className="checkmark"/>
            {label}
        </label>
    );
};

export default FieldsCheckbox;
