import React from "react";
import "./style.scss";

export default function MoreInfo() {
  return (
   <section className="text-center more-info">
    <div className="d-flex w-100 h-100 flex-column">
      <div className="more-info-content">
        <div className="row mr-auto">
          <div className="container more-info-caption">
            <div className="col-md-12">
              <h2 className="more-info-caption__title title_white">Need more information?</h2>
                <p className="more-info-caption__subtitle subtitle_white">Feel free to call if you have any questions</p>
               <div className="phone-number"><a href="tel:708-919-0241p4">708-919-0241 x4</a></div>
                  <p className="more-info-caption__span">CLICK TO CALL US FREE</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}
