import React from "react";

const Fields = ({ className, label, name, type, placeholder, value, onChange }) => {
  return (
      <div>
        <label>{label}</label>
        <input
            className={className}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={e => onChange(e.target.name, e.target.value)}
        />
      </div>
  );
};

export default Fields;


// import React from "react";
//
// const Fields = ({ label, type, placeholder, value, onChange }) => {
//   return (
//     <div>
//     <label>{label}</label>
//       <input type={type} value={value} placeholder={placeholder} onChange={onChange} />
//     </div>
//
//   );
// };
//
// export default Fields;

// or
// class Field extends React.Component {
// 	renderInput({input, label}) {
// 		return(
// 		 <div>
//            <label>{label}</label>
//            <input {...input} />
//          </div>
// 		)
// 	}
// 
// } then add to "input" component="this.renderInput"
