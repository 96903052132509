import React, {useState} from "react";
import { Link }from "react-router-dom";
import "./style.scss";
import {Modal} from "react-bootstrap";
import PopupShipments from "./PopupShipments";
import {PopupWithMap} from "./PopupWithMap/PopupWithMap";


export default function TrackShipmentButton(props) {
    const [modalShow, setModalShow] = useState(false);
    const [mapShow, setMapShow] = useState(false);
    const [mapPoint, setMapPoint] = useState({ lat: 39.909736, lng: -100.453937});

    const backToMenu = event => {
        try {
            if (window.innerWidth < 767) {
                props.click();
            }
        } catch (error) {
            console.log(error);
        }
    };


    const ModalShipments = (props) => {

        return (
            <Modal
                {...props}
                centered>

                <div className="requests-modal">
                    <div className="text-right navicon-new" onClick={() => setModalShow(false)}>
                        <button className='nav-toggle-new active'><span></span></button>
                        <p className="pointer">CLOSE</p>
                    </div>
                    <PopupShipments
                        click={() => {
                            setModalShow(false);
                            setMapShow(true);
                        }}
                        onGetResult={(result) => {
                            setMapPoint(result);
                            setModalShow(false);
                            setMapShow(true);
                        }}
                    />
                </div>
            </Modal>
        );
    };

    const ModalWithMap = (props) => {
        return (
            <Modal
                {...props}
                centered
                dialogClassName="modal-90w"
            >

                {/*<div className="map-modal">*/}
                {/*<div className="text-right navicon-new" onClick={() => setMapShow(false)}>*/}
                {/*    <button className='nav-toggle-new active'><span></span></button>*/}
                {/*    <p>CLOSE</p>*/}
                {/*</div>*/}
                <Modal.Body>
                    <PopupWithMap point={mapPoint} click={() => {
                        setMapShow(false);
                    }}
                    />
                </Modal.Body>
                {/*</div>*/}
            </Modal>
        );
    };


    return (
        <React.Fragment>
            <Link to={`/`}  onClick={() => {
                setModalShow(true);
                backToMenu()}}
            >
                <span className="btn btn-cargo">TRACK SHIPMENT</span>
            </Link>

            <ModalShipments
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
            <ModalWithMap
                show={mapShow}
                onHide={() => setMapShow(false)}

            />
        </React.Fragment>
    );
}
