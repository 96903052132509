import React, {useState} from "react";
import Field from "../_helpers/Fields";
import {client} from "../../api/client";
import "./style.scss";
import ErrorMessage from "../_helpers/ErrorMessage";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {DeliveryDate} from "./Date";

export default function PodRequestForm(props): React.FunctionComponent {
    const initialState = {
        firstName: '',
        email: '',
        origin: '',
        lastName: '',
        deliveryDate: '',
        destination: '',
        number: ''
    };
    const [modalForm, setModalForm] = useState(initialState);
    const [isSubmiting, setIsSubmiting] = useState(true);
    const [errors, setErrors] = useState(initialState);

    const onChange = (name, value) => {
        setModalForm({...modalForm, [name]: value});
    };

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleSubmit = event => {
        document.getElementById('pod').disabled = true;
        event.preventDefault();
        executeRecaptcha('pod_request').then((token) => {
            modalForm.token = token;
            modalForm.form = 'pod-request';
            client.post('/react.php', modalForm)
                .then(response => {
                    document.getElementById('pod').disabled = false;
                    const result = response.data.result;
                    const resErrors = response.data.errors;
                    if (result === 1) {
                        if (resErrors.hasOwnProperty('captcha')) {
                            alert(resErrors.captcha);
                        }
                        setErrors(resErrors);
                        setIsSubmiting(false);
                    } else if (result === 0) {
                        document.getElementById('pod').disabled = false;
                        setModalForm(initialState);
                        props.click();
                        setErrors(initialState);
                    }
                })
                .catch(error => {
                    console.log("An error occurred:", error);
                });
        });
    };

    return (
        <React.Fragment>
            {/*<div className="modal-form">*/}
            <div>
                <h2 className="modal-form__title title_black">POD Request</h2>
                <form className="ui form error" onSubmit={handleSubmit}>
                    <div className="container modal-form-content">
                        <div className="row">
                            <div className="col-md-6">
                                <Field
                                    className={`input ${!isSubmiting && errors.firstName && "error-input"}`}
                                    label="First Name *"
                                    name="firstName"
                                    value={modalForm.firstName}
                                    placeholder="First Name *"
                                    type="text"
                                    onChange={onChange}
                                />
                                    {!isSubmiting &&
                                    <ErrorMessage className='errors' name='firstName' value={errors.firstName}/>}
                            </div>
                            <div className="col-md-6">
                                <Field
                                    className='input'
                                    label="Last Name"
                                    name="lastName"
                                    value={modalForm.lastName}
                                    placeholder="Last Name"
                                    type="text"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-md-6">
                                <Field
                                    className={`input ${!isSubmiting && errors.email && "error-input"}`}
                                    label="Email *"
                                    name="email"
                                    value={modalForm.email}
                                    placeholder="Email *"
                                    type="text"
                                    onChange={onChange}
                                />
                                    {!isSubmiting &&
                                    <ErrorMessage className='errors' name='email' value={errors.email}/>}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="deliveryDate">Delivery Date *</label>
                                <DeliveryDate
                                    className={`input ${!isSubmiting && errors.deliveryDate && "error-input"}`}
                                    onChange={onChange} deliveryDate={modalForm.deliveryDate}/>

                                {!isSubmiting &&
                                <ErrorMessage className='errors' name='deliveryDate' value={errors.deliveryDate}/>}
                            </div>
                            <div className="col-md-6">
                                <Field
                                    className={`input ${!isSubmiting && errors.origin && "error-input"}`}
                                    label="Origin *"
                                    name="origin"
                                    value={modalForm.origin}
                                    placeholder="Origin *"
                                    type="text"
                                    onChange={onChange}
                                />
                                    {!isSubmiting &&
                                    <ErrorMessage className='errors' name='origin' value={errors.origin}/>}
                                </div>
                            <div className="col-md-6">
                                <Field
                                    className={`input ${!isSubmiting && errors.destination && "error-input"}`}
                                    label="Destination *"
                                    name="destination"
                                    value={modalForm.destination}
                                    placeholder="Destination *"
                                    type="text"
                                    onChange={onChange}
                                />
                                    {!isSubmiting &&
                                    <ErrorMessage className='errors' name='destination' value={errors.destination}/>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Field
                                    className={`input ${!isSubmiting && errors.number && "error-input"}`}
                                    label="Your Load Number *"
                                    name="number"
                                    value={modalForm.number}
                                    placeholder="Your Load Number *"
                                    type="text"
                                    onChange={onChange}
                                />

                                    {!isSubmiting &&
                                    <ErrorMessage className='errors' name='number' value={errors.number}/>}

                            </div>
                        </div>

                        <div className="text-center modal-form__button">
                            <button id="pod" type="submit" className="btn btn-cargo">
                                SEND MESSAGE
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    );
}
