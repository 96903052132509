import React from "react";
import "./style.scss";

export function SocialMedia() {
    return (
        <React.Fragment>
            <section className="text-center social-media">
                <h4 className="social-media__title">Find us on Social Media</h4>
                <div className="social-media__icon">
                    <a className="col-md-2" href="https://www.facebook.com/Nova-Carriers-Inc-420535948489917/">
                        <i className="fa fa-facebook-square fa-3x"></i>
                    </a>
                    <a className="col-md-2" href="https://www.linkedin.com">
                        <i className="fa fa-linkedin-square fa-3x"></i>
                    </a>
                </div>
            </section>
        </React.Fragment>
    );
}
