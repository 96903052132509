import React, {useState} from "react";
import "./style.scss";
import FormText, {FormLawNotice} from "./FormText";
import Field from "../_helpers/Fields";
import FieldsCheckbox from "../_helpers/FieldsCheckbox";
import DragAndDrop from "./DragAndDrop";
import {BirthDay} from "./Date";
import "react-datepicker/dist/react-datepicker.css";
import {client} from "../../api/client";
import {Signature} from "./Signature";
import ErrorMessage from "../_helpers/ErrorMessage";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {SocialMedia} from "../SocialMedia/SocialMedia";
import {CallUs} from "../CallUs/CallUs";


export default function AuthPage(): React.FunctionComponent {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = date.getFullYear();

    const today = mm + '/' + dd + '/' + yyyy;

    const initialState = {
        dateAuth: today,
        firstName: "",
        phone: "",
        license: "",
        lastName: "",
        email: "",
        stateIssued: "",
        birthDay: "",
        position: "",
        security: "",
        street: "",
        city: "",
        region: "",
        postal: "",

        agreement: "",
        california: "",
        minnesota: "",
        oklahoma: "",
        cdl: [],
        medicalCard: [],
        signatureURL: "",
        survey: "",
        personWhoRecommended: ""
    };

    const [authForm, setAuthForm] = useState(initialState);
    const [isSubmiting, setIsSubmiting] = useState(true);
    const [errors, setErrors] = useState(initialState);
    const [signatureCanvas, setSignatureCanvas] = useState(null);
    const [hideForm, setHideForm] = useState(false);

    const showForm = () => {
        setHideForm(false);
    };

    const resultForm = {
        dateAuth: authForm.dateAuth,
        firstName: authForm.firstName,
        phone: authForm.phone,
        license: authForm.license,
        lastName: authForm.lastName,
        email: authForm.email,
        stateIssued: authForm.stateIssued,
        birthDay: authForm.birthDay,
        position: authForm.position,
        security: authForm.security,
        street: authForm.street,
        city: authForm.city,
        region: authForm.region,
        postal: authForm.postal,

        agreement: authForm.agreement,
        california: authForm.california,
        minnesota: authForm.minnesota,
        oklahoma: authForm.oklahoma,
        cdl: authForm.cdl,
        medicalCard: authForm.medicalCard,
        signatureURL: authForm.signatureURL,
        survey: authForm.survey,
        personWhoRecommended: authForm.personWhoRecommended
    };

    const goto = e => {
        e.preventDefault();
        const selector = document.getElementById('agree');
        if (selector) {
            const position = selector.getBoundingClientRect();
            if (position.top >= 0 && position.bottom <= window.innerHeight) return;
            selector.scrollIntoView({block: "center", behavior: "smooth"});
        }
    };

    const onChange = (name, value) => {
        setAuthForm({
            ...authForm, [name]: value
        });
    };

    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleSubmit = event => {
        document.getElementById('auth').disabled = true;
        event.preventDefault();
        executeRecaptcha('authorization_form').then((token) => {
            resultForm.token = token;
            resultForm.form = 'authorization-form';
            client.post('/react.php', resultForm)
                .then(response => {
                    const result = response.data.result;
                    const resErrors = response.data.errors;
                    if (result === 1) {
                        document.getElementById('auth').disabled = false;
                        if (resErrors.hasOwnProperty('captcha')) {
                            alert(resErrors.captcha);
                        }
                        setErrors(resErrors);
                        setIsSubmiting(false);
                        goto(event);
                    } else if (result === 0) {
                        document.getElementById('auth').disabled = false;
                        setHideForm(true);
                        setAuthForm(initialState);
                        setIsSubmiting(true);
                        setErrors(initialState);
                        window.dragAndDropMedicalCard.clearThumbs();
                        window.dragAndDropCDL.clearThumbs();
                        signatureCanvas.current.clear();
                    }
                })
                .catch(error => {
                    console.log("An error occurred:", error);
                });
        });

    };


    return (
        <section className="auth-page">
            {!hideForm ?
                <React.Fragment>
                    <FormText/>
                    <form className="auth-page-form" onSubmit={handleSubmit}>
                        <div className="container checkbox-block">
                            <div className="auth-page-form-checkbox" id="agree">
                                <FieldsCheckbox
                                    label="I Agree and Authorize Nova Carriers, Inc. to conduct PSP and MVR records' check."
                                    value="I Agree"
                                    checked={authForm.agreement}
                                    type="checkbox"
                                    name="agreement"
                                    onChange={onChange}
                                />
                            </div>
                            <div className="container auth-page-form-checkbox_error">
                                {!isSubmiting && <ErrorMessage name='agreement' value={errors.agreement}/>}
                            </div>
                        </div>
                        <div className="container auth-page-form-content">
                            <div className="row">
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className='input'
                                        label="Date *"
                                        name="dateAuth"
                                        value={today}
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-md-8">
                                    <label htmlFor="signature">Signature *</label>
                                    <Signature
                                        className={`canvas__border ${!isSubmiting && errors.signatureURL && "error-input"}`}
                                        name="signatureURL"
                                        onChange={onChange}
                                        error={errors.signatureURL}
                                        setCanvas={setSignatureCanvas}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.firstName && "error-input"}`}
                                        label="First Name *"
                                        name="firstName"
                                        value={authForm.firstName}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='firstName' value={errors.firstName}/>}
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.lastName && "error-input"}`}
                                        label="Last Name *"
                                        name="lastName"
                                        value={authForm.lastName}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='lastName' value={errors.lastName}/>}
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <label htmlFor="birthDay">Date of Birth *</label>
                                    {(window.innerWidth > 992 && false) ?
                                        <BirthDay
                                            className={`input ${!isSubmiting && errors.birthDay && "error-input"}`}
                                            onChange={onChange} birthDay={authForm.birthDay}/>
                                        :
                                        <Field
                                            className='input'
                                            name="birthDay"
                                            value={authForm.birthDay}
                                            type="text"
                                            onChange={onChange}
                                        />
                                    }
                                    {!isSubmiting && <ErrorMessage name='birthDay' value={errors.birthDay}/>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.phone && "error-input"}`}
                                        label="Phone *"
                                        name="phone"
                                        value={authForm.phone}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='phone' value={errors.phone}/>}
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className='input'
                                        label="Email"
                                        name="email"
                                        value={authForm.email}
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className='input'
                                        label="Position"
                                        name="position"
                                        value={authForm.position}
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.license && "error-input"}`}
                                        label="Driver’s License Number *"
                                        name="license"
                                        value={authForm.license}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='license' value={errors.license}/>}
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.stateIssued && "error-input"}`}
                                        label="State Issued *"
                                        name="stateIssued"
                                        value={authForm.stateIssued}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='stateIssued' value={errors.stateIssued}/>}
                                </div>
                                <div className="col-md-4 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.security && "error-input"}`}
                                        label="Social Security # *"
                                        name="security"
                                        value={authForm.security}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='security' value={errors.security}/>}
                                </div>
                            </div>
                            <div className="row drop-zone">
                                <div className="col-md-6">
                                    <label htmlFor="cdl">Copy of CDL *</label>
                                    <DragAndDrop
                                        className={`${!isSubmiting && errors.cdl && "drop-error"}`}
                                        id="cdl"
                                        dataFiles={authForm.cdl}
                                        onChange={onChange}
                                        name="cdl"
                                        ref={(dragAndDropCDL) => {
                                            window.dragAndDropCDL = dragAndDropCDL
                                        }}
                                    />
                                    {!isSubmiting && <ErrorMessage name='cdl' value={errors.cdl}/>}
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="medicalCard">Copy of Medical Card</label>
                                    <DragAndDrop
                                        id="medicalCard"
                                        dataFiles={authForm.medicalCard}
                                        onChange={onChange}
                                        name="medicalCard"
                                        ref={(dragAndDropMedicalCard) => {
                                            window.dragAndDropMedicalCard = dragAndDropMedicalCard
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.street && "error-input"}`}
                                        label="Street Address *"
                                        name="street"
                                        value={authForm.street}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='street' value={errors.street}/>}
                                </div>
                                <div className="col-md-3 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.city && "error-input"}`}
                                        label="City *"
                                        name="city"
                                        value={authForm.city}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='city' value={errors.city}/>}
                                </div>
                                <div className="col-md-3 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.region && "error-input"}`}
                                        label="State/Region/Province *"
                                        name="region"
                                        value={authForm.region}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='region' value={errors.region}/>}
                                </div>
                                <div className="col-md-3 mr-auto auth-page-form-content__item">
                                    <Field
                                        className={`input ${!isSubmiting && errors.postal && "error-input"}`}
                                        label="Postal / Zip Code *"
                                        name="postal"
                                        value={authForm.postal}
                                        type="text"
                                        onChange={onChange}
                                    />
                                    {!isSubmiting && <ErrorMessage name='postal' value={errors.postal}/>}
                                </div>
                            </div>
                            <FormLawNotice/>
                        </div>
                        <div className="container auth-page-form-checkbox checkbox-block">
                            <FieldsCheckbox
                                label="YES, I am a California resident and would like a free copy of my investigative consumer report."
                                value="I am a California"
                                checked={authForm.california}
                                type="checkbox"
                                name="california"
                                onChange={onChange}
                            />
                            <FieldsCheckbox
                                label="YES, I am a Minnesota resident and would like a free copy of my consumer report."
                                value="I am a Minnesota"
                                checked={authForm.minnesota}
                                type="checkbox"
                                name="minnesota"
                                onChange={onChange}
                            />
                            <FieldsCheckbox
                                label="YES, I am an Oklahoma resident and would like a free copy of my consumer report."
                                value="I am an Oklahoma"
                                checked={authForm.oklahoma}
                                type="checkbox"
                                name="oklahoma"
                                onChange={onChange}
                            />
                        </div>

                        <div className="container">
                            <div className="row survey">
                                <div className="col-6">
                                    <label htmlFor="survey">HOW DID YOU HEAR ABOUT US? *</label> <br/>
                                    <select className={`input ${!isSubmiting && errors.survey && "error-input"}`}
                                            id="survey" name="survey"
                                            value={authForm.survey}
                                            onChange={e => onChange(e.target.name, e.target.value)}>
                                        <option value="" disabled>Please select</option>
                                        <option value="Search Engine">Search Engine</option>
                                        <option value="Social media">Social media</option>
                                        <option value="Recommended">Recommended by friend or colleague</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {!isSubmiting && <ErrorMessage name='survey' value={errors.survey}/>}
                                </div>
                                <div className="col-6">
                                    <Field
                                        className='input'
                                        label="Name of the person you were talking to"
                                        name="personWhoRecommended"
                                        value={authForm.personWhoRecommended}
                                        type="text"
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container text-center auth-page-form-content__button">
                            <button id="auth" type="submit" className="btn btn-cargo">
                                SUBMIT
                            </button>
                            {!isSubmiting &&
                            <div className='errors error-submit'>{'Please fill out all required fields'}</div>}
                        </div>
                    </form>
                    <CallUs/>
                    <SocialMedia/>
                </React.Fragment>
                :
                <div className="check-content text-center">
                    <div className="check-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" height="68" viewBox="0 0 24 24" width="68">
                            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none"/>
                            <path
                                d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
                        </svg>
                    </div>
                    <h3 className="font-weight-bold">Success</h3>
                    <p style={{fontSize: "16px"}}>Your message has been sent successfully!</p>
                    <div className="text-center form-content__button">
                        <button className="btn-sendAgain" style={{backgroundColor: "#f5f7f9"}} onClick={showForm}>Send
                            another message
                        </button>
                    </div>
                </div>
            }
        </section>
    );
}
