import React from "react";
import echo from "../../assets/img/Image_26.png";
import paramount from "../../assets/img/Image_21.png";
import modetransp from "../../assets/img/Image_22.png";
import rjw from "../../assets/img/Image_24.png";
import schneider from "../../assets/img/Image_25.png";
import hubgroup from "../../assets/img/Image_23.png";
import ktb from "../../assets/img/Image_27.png";
import "./style.scss";

export default function OurPartners() {
    return (
        <section className="partners">
            <div className="container-fluid text-center">
                <h1 className="partners__title title_black">Our Partners</h1>
                <div className="partners-content">
                    <span className="partners-content__span Echo"><img src={echo} alt="echo"/></span>
                    <span className="partners-content__span Paramount"><img src={paramount} alt="paramount"/></span>
                    <span className="partners-content__span Modetransp"><img src={modetransp} alt="modetransp"/></span>
                    <span className="partners-content__span Rjw"><img src={rjw} alt="rjw"/></span>
                    <span className="partners-content__span Schneider"><img src={schneider} alt="schneider"/></span>
                    <span className="partners-content__span Hubgroup"><img src={hubgroup} alt="hubgroup"/></span>
                    <span className="partners-content__span Ktb"><img src={ktb} alt="ktb"/></span>

                </div>
            </div>
        </section>
    );
}
