import React from "react";
import "../GetQuote/style.scss";
import GetQuoteForm from "../GetQuote/GetQuoteForm";
import {ParagraphGetQuickQuote} from "../../assets/Variables";
import {PhoneNumber} from "../../assets/PhoneNumber";

export default function GetQuote(props) {

    return (
        <section className="requests" id="requests">
            <div className="d-flex w-100 h-100 flex-column">
                <div className="requests-content">
                    <div className="container">
                        <div className="row requests-content__item">
                            <div className="col-xl-6 text-center mr-auto">
                                <div className="requests-caption">
                                    <h2 className="requests-caption__title title_white">Get a quick quote</h2>
                                   <ParagraphGetQuickQuote/>
                                    <div className="phone-number">
                                        <PhoneNumber/>
                                    </div>
                                    <p className="requests-caption__span">CLICK TO CALL US FREE</p>
                                </div>
                            </div>
                            <div className="col-xl-5 mr-auto">
                                <GetQuoteForm isSubmiting={props.isSubmiting}
                                              showMessage={props.showMessage}
                                              updateData={props.updateData}/>
                            </div>
                            <div className="col-xl-1 mr-auto"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
}
